import { usePageProps } from '@/shared/composables/use-page-props.ts';

export const can = (permission: string, resource?: any): boolean => {
    const pageProps = usePageProps();

    if (!pageProps.value.user || !pageProps.value.tenant) {
        return false;
    }

    if (!resource && pageProps.value.user.permissions) {
        const module = permission.replace('access.', '');

        if (permission.startsWith('access.') && pageProps.value.tenant.modules[module] !== undefined) {
            return pageProps.value.user.permissions[permission] && pageProps.value.tenant.modules[module] || false;
        }

        return pageProps.value.user.permissions[permission] || false;
    }

    if (typeof resource == 'object' && 'permissions' in resource && permission) {
        return resource.permissions[permission] || false;
    }

    return false;
};
